import { Controller } from '@hotwired/stimulus';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import { Turbo } from '@hotwired/turbo-rails';
import { saveAs } from 'file-saver';

cytoscape.use(dagre);

export default class extends Controller {
  static targets = ['container', 'style', 'maximizeButton', 'minimizeButton'];

  static values = {
    elements: String,
    name: String,
  };

  style = [{
    selector: 'node',
    style: {
      shape: 'data(type)',
      label: 'data(label)',
      height: 100,
      width: 200,
      'text-valign': 'center',
      'text-halign': 'center',
      'text-wrap': 'wrap',
      'text-max-width': 200,
      'background-color': '#F1F7F9',
      'border-color': '#a3b8bd',
      'border-width': 2,
      color: '#09181f',
    },
  },
  {
    selector: 'node.current',
    style: {
      'border-color': '#1689ac',
      color: '#186a86',
    },
  },
  {
    selector: 'node.serviced',
    style: {
      'background-color': '#d4f3f1',
    },
  },
  {
    selector: 'edge',
    style: {
      'curve-style': 'bezier',
      'target-arrow-shape': 'triangle',
      'target-arrow-color': '#a3b8bd',
      width: 4,
      'line-style': 'data(line)',
      'line-color': '#a3b8bd',
      label: 'data(label)',
      'text-wrap': 'wrap',
      'text-outline-color': '#fff',
      'text-outline-width': 2,
      color: '#09181f',
    },
  }];

  connect() {
    const cy = cytoscape({
      container: this.containerTarget,
      elements: JSON.parse(this.elementsValue),
      layout: {
        name: 'dagre',
        avoidOverlap: true,
        nodeDimensionsIncludeLabels: true,
      },
      wheelSensitivity: 0.2,
      style: this.style,
    });
    cy.on('tap', 'node', function visitPath() {
      if (this.data('href')) {
        Turbo.visit(this.data('href'));
      }
    });
    this.cy = cy;
  }

  disconnect() {
    this.cy.unmount();
  }

  openFullscreen(element) {
    element.preventDefault();

    if (this.element.requestFullscreen) {
      this.element.requestFullscreen();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  closeFullscreen(element) {
    element.preventDefault();

    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  fullscreenChanged() {
    if (document.fullscreenElement) {
      this.maximizeButtonTarget.classList.add('hidden');
      this.minimizeButtonTarget.classList.remove('hidden');
    } else {
      this.maximizeButtonTarget.classList.remove('hidden');
      this.minimizeButtonTarget.classList.add('hidden');
    }

    // Scale the graph to fit the window with timeout to give cytoscape time to adjust to the new display size
    setTimeout(() => { this.cy.animate({ fit: { padding: 20 } }, { duration: 300 }, { easing: 'ease-in-out' }); }, 300);
  }

  fitGraph() {
    this.cy.animate({ fit: { padding: 20 } }, { duration: 300 }, { easing: 'ease-in-out' });
  }

  downloadImage() {
    const currentDate = new Date().toJSON().slice(0, 10);
    const name = this.nameValue.replace(/[^a-zA-Z ]/g, '').replace(/ /g, '-').toLowerCase();
    saveAs(this.cy.png({ bg: '#fff', full: true }), `${name}-structure-diagram-${currentDate}.png`);
  }
}
