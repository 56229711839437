import Sortable from 'stimulus-sortable';

export default class extends Sortable {
  static values = {
    form: Boolean,
  };

  connect() {
    super.connect();
  }

  onUpdate(event) {
    if (this.formValue) {
      this.updateOrder();
    }
    super.onUpdate(event);
  }

  updateOrder() {
    const items = Array.from(this.sortable.el.children);

    items.forEach((item) => {
      item.querySelector('.handle input').value = items.indexOf(item) + 1;
    });
  }
}
